.Subscribe {
    text-align: left;
    margin: 20px 50px;
}

.Select{
    width:100%;
}

.Notes{
    resize: none;
    min-height: 250px;
}

.SubscribeBtn{
    min-width: 200px;
}

.AddressGroup{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.DisplayTextArea{
    width:100%;
    resize: none;
    border: 0.5 solid gray;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.RequestButtonContainer{
    width:100%;
    display: flex;
    justify-content: space-between;
}

#Select 
{
    width: 200px!important;
}