.TopBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 100%;
    z-index: 500;
}

.TopBarButton{
    max-width: 200px;
    width: 200px;
    float: left;
    border: 0 !important;
    margin: 0px;
    padding: 10px 0px;
}