.PayDebt {}

.FieldsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.TextArea{
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}

#HorizontalContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    width: 100%;
    text-align: center;
}

.PaymentsContainer{
    overflow-y: scroll;
    overflow-x: auto;
    width: 100%;
    max-height: 500px;
}

.HorizontalContainerFlex{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    text-align: center;
}