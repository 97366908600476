.ToolsItemsBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    gap: 10px;
}

.ToolsBarButton{
    padding: 10px;
}

.ToolsItemsBarButtons{
    display: flex;
    flex-direction: row;
    gap:10px;
}

.ResultsCard{
    padding: 10px;
}

.NumbersContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
}