.Layout{
    
}

.DashboardLayout {
    display: grid;
    grid-template-columns: 1fr 5fr;
    text-align: center;
    gap: 20px;
    min-height: 600px;
}

.DashboardLayoutNoSideBar{
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    gap: 20px;
    min-height: 600px;
}

@media only screen and (max-width: 990px) {
    .DashboardLayout {
        grid-template-columns: 1fr;
    }
}