.Navigation {
    z-index:1000;
}

.title-description{
    font-size:50%;
}

.CenterDivTopBar{
    width:85%;
}

.DashboardLabel{
    display: flex;
    flex-direction: row;
    gap: 2px;
}