.PrivilegesManage {
    text-align: left;
    margin: 20px 50px;
}

.Select{
    width:100%;
}

.Notes{
    resize: none;
    min-height: 250px;
}

.PrivilegesManageBtn{
    min-width: 200px;
}

.AddressGroup{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.EntityCard{
    margin-bottom: 20px;
    padding: 20px;
}

.CheckBoxDataTable
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

.ButtonsContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.PrivilegesContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px;
}

.EntityCardTitle{
    font-weight: bold;
}