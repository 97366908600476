.ToolsBar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 100%;
    z-index: 500;
    padding-right: 20px;
    padding-left: 10px;
}

#searchToolsBar{
    
}

.searchToolsBar{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    gap: 5px;
}



.selectsGroup, .subSelectsGroup{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

@media (max-width:650px) {
    .selectsGroup{
        flex-direction: column;
        gap:20px;
    }
}
@media (max-width:850px){
    .subSelectsGroup{
        flex-direction: column;
        gap:20px;
    }
}

.datePickerWrap{
    width:auto!important;
}

#SelectAction, #SelectUser,
#SelectAsset, .ApplyFilterButton, .ClearFilterButton, .datePickerWrap, .datePickerWrap input
{
    width: 200px!important;
}

#ButtonsContainer{
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}