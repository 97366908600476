.Accounting {
    margin-top: 10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Accounting table{
  width:95%;
}

@media (max-width:700px) {
  .hidableColumn{
    display:none;
  }
}

.CheckBoxDataTable
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

#SelectType{
  width: 200px;
}

.TypeContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

#SelectType .select-search__select{
  z-index: 9999;
}

.TopSelectContainer{
  display:flex;
  flex-direction: row;
  gap: 20px;
}

.StatisticsContainer{
  display: flex;
  flex-direction: row;
  gap:10px;
}