.UpdateAdd {}

.FieldsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.TextArea{
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}