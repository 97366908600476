.Update {}

.FieldsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.Frame{
    min-height: 500px;
    width: 100%;
}