.Email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 50px;
}

.label {
    font-weight: bold;
}

.Email table{
    width:80%;
}

.ToolsButtons{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.table{
    text-align: center;
}

.header_label{
    font-weight: bolder;
}


.TextArea{
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}

.SendButton{
    width:200px;
}