.DeletedItem {
    margin-top: 10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DeletedItem table{
  width:95%;
}

@media (max-width:700px) {
  .hidableColumn{
    display:none;
  }
}

.CheckBoxDataTable
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}