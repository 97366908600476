.View {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.label {
    font-weight: bold;
}

.View table{
    width:80%;
}

.ToolsButtons{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.table{
    text-align: center;
}

.header_label{
    font-weight: bolder;
}