.AddEdit {
    text-align: left;
    margin: 20px 50px;
}

.Select{
    width:100%;
}

.Notes{
    resize: none;
    min-height: 250px;
}

.AddEditBtn{
    min-width: 200px;
}

.AddressGroup{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.TextArea{
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}