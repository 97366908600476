.ModalComponent {}

.Modal{

}

.ModalBody{
    
}

.ModalHeader{
    display: table;
    padding: 1rem;
}

.ModalHeader td:nth-child(1){
    padding: 10px;
}

.ModalClose{
    padding: 0!important;
}