.Login {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:40%;
    border: 1px solid rgba(150, 150, 150, 0.5);
    padding:50px 100px;
    border-radius: 35px;
    box-shadow:1px 1px lightblue;
    margin: auto;
    margin-top: 100px;
}

.topContainer{
    width:100%;
    border-bottom:solid rgba(150, 150, 150, 0.5) 1px;
    display:flex;
    justify-content: center;
}

.leftContainer{
    width:100%;
}

.loginButton{
    width:90%;
    border-radius: 20px;
}

.signupContainer{
    display: flex;
    flex-direction: row;
    gap:10px;
}