.Statistics {}

.SelectContainer{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.SelectContainer button{
    
}

#SelectCompany{

}

.searchToolsBar{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    gap: 5px;
}


.selectsGroup, .subSelectsGroup{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

@media (max-width:650px) {
    .selectsGroup{
        flex-direction: column;
        gap:20px;
    }
}
@media (max-width:850px){
    .subSelectsGroup{
        flex-direction: column;
        gap:20px;
    }
}

#SelectMultiple, #SelectMultiple1,
#SelectMoneyReceived, #SelectCompany, #SelectVendor, #SelectAgent, #SelectCommissary, #SelectCustomer,
#SelectMailStatus, .ApplyFilterButton, .ClearFilterButton, .datePickerWrap, .datePickerWrap input
{
    width: 200px!important;
}