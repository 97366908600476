.main{
    min-height:80vh;
}

.centerVertically{
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.centerVertically{
    display: flex;
    align-items: center;
}