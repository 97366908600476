.AddEdit {
    text-align: left;
    margin: 20px 50px;
}

.Select{
    width:100%;
}

.Notes{
    resize: none;
    min-height: 250px;
}

.AddEditBtn{
    min-width: 200px;
}

.AddressGroup{
    display: flex;
    flex-direction: column;
    gap: 10px;
}