.AddEdit {
    text-align: left;
    margin: 20px 50px;
}

.Select, .SelectIsEnabled, .SelectRole{
    width:100%;
}

.Notes{
    resize: none;
    min-height: 250px;
}

.AddEditBtn{
    min-width: 200px;
}

.AddressGroup{
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.TextArea{
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}


.AddressesBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
}


.AddressesList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow-y: scroll;
    max-height: 200px;
    min-height: 200px;
    gap: 5px;
    padding: 10px;
}

.AddressListItem{
    display: flex;
    flex-direction: row;
    max-height: 40px;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.AddressListItemInternal{
    display: flex;
    flex-direction: row;
    max-height: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.AddressListItemBtn{
    border-radius: 50%;
    height: 25px;
    width: 25px;
}